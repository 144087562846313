@charset "UTF-8";

@import '~leaflet/dist/leaflet.css';
@import '~react-leaflet-markercluster/dist/styles.min.css';
@import '~material-design-icons/iconfont/material-icons.css';


/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
    url('resources/fonts/roboto-v20-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('resources/fonts/roboto-v20-latin-regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url('resources/fonts/roboto-v20-latin-italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('resources/fonts/roboto-v20-latin-italic.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('resources/fonts/roboto-v20-latin-500.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('resources/fonts/roboto-v20-latin-500.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('resources/fonts/roboto-v20-latin-500italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('resources/fonts/roboto-v20-latin-500italic.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('resources/fonts/roboto-v20-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('resources/fonts/roboto-v20-latin-700.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
    url('resources/fonts/roboto-v20-latin-700italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('resources/fonts/roboto-v20-latin-700italic.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-mono-500 - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  src: url('resources/fonts/roboto-mono-v12-latin-500.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('resources/fonts/roboto-mono-v12-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('resources/fonts/roboto-mono-v12-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('resources/fonts/roboto-mono-v12-latin-500.woff') format('woff'),
    /* Modern Browsers */
    url('resources/fonts/roboto-mono-v12-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('resources/fonts/roboto-mono-v12-latin-500.svg#RobotoMono') format('svg');
  /* Legacy iOS */
}

html {  overflow: hidden; }

html, body {
    overscroll-behavior-y: none;
}

body {
  font: 90%/165% 'Roboto', Arial, Helvetica, sans-serif, sans-serif;
}

/* Colors */
$primary: #4ca9f7;
$secondary: #0088fa;
$third: #60adec;
$fourth: #276997;
$fifth: #1c5581;
$sixth: #4496d7;

$base: #333;
$base2: #666;
$base3: #999;

$neutral1: #fff;
$neutral2: #f1f1f1;
$neutral3: #f2f2f2;
$neutral4: #cde6fb;
$neutral5: #ccc;
$neutral6: #ddd;
$neutral7: #f9f9f9;

$alert: red;
$ok: rgb(13, 163, 46);
$ok-hover: rgb(15, 209, 57);
$special: rgb(202, 23, 23);
$special-hover: rgb(255, 238, 0);
$favorie: crimson;


* {
  transition: none !important;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
}


/** General **/
.tagview-page .details::-webkit-scrollbar,
.locationView-page .details::-webkit-scrollbar,
#place::-webkit-scrollbar,
#page::-webkit-scrollbar {
  width: 10px;
}

.tagview-page .tag-details::-webkit-scrollbar,
.search-control-info-list::-webkit-scrollbar,
.leaflet-popup-content .map-innercontainer::-webkit-scrollbar,
.module-view #details .innercontainer::-webkit-scrollbar,
.description.white-space::-webkit-scrollbar,
#place .place-innercontainer::-webkit-scrollbar,
ul#places::-webkit-scrollbar,
.leaflet-popup-content-wrapper::-webkit-scrollbar,
.locationGrid-page .location .innercontainer .location-body::-webkit-scrollbar {
  width: 5px;
}

.tagview-page .tag-details,
.tagview-page .details,
.search-control-info-list,
.leaflet-popup-content .map-innercontainer,
.module-view #details .innercontainer,
.locationView-page .details,
.description.white-space,
#place,
#page,
#place .place-innercontainer,
ul#places,
.leaflet-popup-content-wrapper,
.locationGrid-page .location .innercontainer .location-body {
  scrollbar-color: $neutral5 white;
  scrollbar-width: thin;
}

.tagview-page .tag-details::-webkit-scrollbar-thumb,
.tagview-page .details::-webkit-scrollbar-thumb,
.search-control-info-list::-webkit-scrollbar-thumb,
.leaflet-popup-content .map-innercontainer::-webkit-scrollbar-thumb,
.module-view #details .innercontainer::-webkit-scrollbar-thumb,
.locationView-page .details::-webkit-scrollbar-thumb,
.description.white-space::-webkit-scrollbar-thumb,
#place::-webkit-scrollbar-thumb,
#page::-webkit-scrollbar-thumb,
#place .place-innercontainer::-webkit-scrollbar-thumb,
ul#places::-webkit-scrollbar-thumb,
.leaflet-popup-content-wrapper::-webkit-scrollbar-thumb,
.locationGrid-page .location .innercontainer .location-body::-webkit-scrollbar-thumb {
  background: $neutral5;
}

.flex {
  display: -moz-flex;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -moz-flex-warp: warp;
  -webkit-box-pack: flex-around;
  -ms-flex-pack: flex-around;
  justify-content: flex-around;
  -webkit-justify-content: space-around;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  flex-direction: row;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-left {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}


a,
a:visited {
  color: $secondary;
}

a:hover,
a:active {
  color: $special;
}

/** Base **/
.container {
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  background: $neutral3;
  position: absolute;
}

#page {
  height: calc(100vh - 56px);
  width: 100%;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  background: $neutral3;
}

.innerwidth {
  max-width: 1280px;
  width: auto;
  margin: 0 auto !important;
}

#page .innerwidth:first-child {
  margin-top: 30px !important;
}

.innercontainer {
  margin: 20px;
}



#page.content-page .innercontainer {
  background: $neutral1 !important;
  padding: 30px;
  border-radius: 5px;
}

.innercontainer.search {
  width: auto !important;
}

main {
  position: relative;
  height:100vh;
}

.copyToClipBoard {
  font-size: .7rem;
  width: 100%;
  border: 1px solid $neutral2;
  color: $base3;
  background: $neutral1;
  margin-top: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
  
}

.copyToClipBoard:hover {
  background: $neutral7;
  border-color: $neutral6;
}

.copyToClipBoard .material-icons {
  font-size: .85rem;
  top: 0px;
  margin-right: 5px;
  position: relative;
}

#ShareAndCopy {
  margin: 23px 0px 25px 0px;
}

#ShareAndCopy .copyToClipBoard {
  width: calc(100% - 60px);
  margin-top: 0px;
}

#ShareAndCopy .shareButton {
  width: 60px;
  border-radius: none !important;
  font-size: .8rem;
  background: $base3;
  border: none;
  border-radius: 0px !important;
}

#ShareAndCopy .shareButton:hover {
  background: $secondary;
}

button.react-share__ShareButton {
  margin: 5px;
}

button.react-share__ShareButton:hover {
  transform: scale(1.3);
}

.modal-dialog.modal-sm.modal-dialog-centered {
  max-width: 340px;
  margin: 0 auto;
}

.modal-dialog.modal-90w.modal-lg.modal-dialog-centered {
  margin: 0 auto;
}

.modal-backdrop {
  z-index: 9999 !important;
}

.modal {
  z-index: 99992 !important;
}

.shareButtons .modal-body {
  text-align: center;
}



/** List and forms **/
.list {
  text-align: left;
  margin: auto;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

input:required,
textarea:required {
  border-color: $ok;
}

input:invalid,
textarea:invalid {
  border-color: $alert;
}

span.required {
  color: $alert;
}

.open,
.edit {
  font-size: .7rem;
  padding: 5px;
  width: 150px;
  border-radius: 5px;
  display: block;
  text-align: center;
  font-weight: bold;
  border: 1px solid $neutral6;
  float:right;

}

.open {
  color: $secondary;
}

.open:hover {
  text-decoration: none;
  background: $secondary;
  border-color: $secondary;
  color: $neutral1;
}

.edit {
  color: $special;
}

.edit:hover {
  text-decoration: none;
  background: $special;
  border-color: $special;
  color: $neutral1;
}

.subdetails button {
  border: 0px;
  padding: 10px;
  margin: 5px;
}

.subdetails button:hover {
  background: $special;
}

.white-space {
  white-space: pre-wrap;
}

code {
  color: $special;
}

blockquote {
  background: $neutral7;
  padding: 20px 30px 20px 30px;
}

blockquote ol {
  padding: 0px 0px 0px 0px;
  margin: 0px;
  list-style: none,
}

blockquote ol li {
  margin-bottom: 10px;
  counter-increment: my-awesome-counter;
}

blockquote ol li:last-child {
  margin-bottom: 0px;
}

ol li::before {
  content: counter(my-awesome-counter) ". ";
  color: $special;
  font-size: .9em;
}

/** Bootstarp buttons **/
.btn-secondary {
  background: $secondary;
  border-color: $secondary
}

.btn-secondary:hover {
  background: $ok;
  border-color: $ok
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled):active {
  background: $ok !important;
  border-color: $ok;
}

.btn-outline-info {
  color: $secondary;
  border-color: $neutral6;
  padding: 15px;
}

.btn-outline-info:hover {
  color: $neutral1;
  border-color: $fourth;
  background-color: $fourth;
}

.dropdown-menu.show,
button#dropdown-basic {
  width: 200px !important;
}

a.dropdown-item {
  font-size: .9em;
}

/** Add, edit, places **/

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.subdetails {
  margin: 0 15px 0 15px;
}

.form-group {
  background: $neutral1;
  padding: 20px 30px;
  border-radius: 5px;
}

form label {
  font-weight: bold;
  font-size: .9em;
}

form input[type=radio] {
  font-size: 1px;
}

form input,
form textarea {
  margin: 5px 0px 20px 0px;
  border-radius: 5px !important;
}

small {
  font-size: .8em;
}

small.even-smaller {
  font-size: .69em;
  line-height:2em;
  color: #999;
}

/** Nav **/
.navbar {
  position: relative;
  width: 100%;
}


.navbar-dark .navbar-brand {
  font-size: 1em;
  font-weight: bold;
  margin-left: -10px;
  margin-right: 20px;

}

nav {
  background: $primary;
  color: $neutral1;
}

nav a,
nav a:visited {
  font-size: .95em;
  color: $neutral1 !important;
  margin: 0px 5px 0px 0px;
  border-radius: 5px;
  padding: 8px 15px 9px 15px !important;
}

nav ul {
  margin: 0px;
  padding: 0px;
  display: inline;
}

nav ul li {
  list-style: none;
  display: inline-block;
  margin-right: 15px;
  font-weight: bold;
}


nav a:hover,
nav a:active,
nav .nav-link.active,
nav .nav-link.active:hover {
  color: $neutral1;
  background: $secondary;

}

nav a .material-icons {
  font-size: 15px !important;
  position: relative;
  top: 2px;
  margin-right: 5px;
}

nav .nav-link.add-new {
  background: $secondary;
  right: 16px;
  position: absolute;
  top: 8px;
}

nav .nav-link.add-new:hover {
  background: $special;
}

nav #logo:hover {
  background: transparent;
}

nav .navbar-toggler {
  border-color: $neutral4;
}

nav .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}

nav a.navbar-brand:hover,
nav a.navbar-brand:active {
  background: transparent !important;
}

/** List view (Frontpage) **/
aside {
  border-right: 1px solid $neutral2 !important;
  width: 351px;
  position: absolute;
  height: 100vh;
  z-index: 2;
}

#places {
  width: 350px;
  height: calc(100vh - 160px);
  position: absolute;
  overflow-y: auto;
  border-top: 1px solid $neutral2;
}


#places .list-group-item {
  border-color: $neutral2;
  font-size: .95em;
  border-left: 0px;
}

#places .list-group-item:first-of-type {
  border-top: 0px;
}

#places .list-group-item:last-of-type {
  border-bottom: 0px;
}

#places .list-group-item .material-icons {
  font-size: .9em;
}

#places .list-group-item:hover {
  cursor: pointer;
  background: $neutral4;
  border-color: $neutral4;
}

#places .list-group-item.active .coordinates {
  color: $neutral1;
}

#places .list-group-item.active {
  background: $primary;
  border: 0px solid $primary;
}

#places .list-group-item .coordinates {
  font-size: .75em;
  color: $base3;
}

#places .list-group-item .coordinates .material-icons {
  position: relative;
  top: 1.5px;
  margin-right: 3px;
}

#places,
#places .list-group-item:first-child,
#places .list-group-item:last-child {
  border-radius: 0px !important;
}

#location-list .spinner-border {
  position: absolute !important;
  top: 50vh !important;
  left: 155px !important;
  ;
  font-size: 8px !important;
}

#page .spinner-border {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  font-size: 8px !important;
}

#location-list .flickr-lightbox-container .spinner-border.text-primary {
  position: absolute !important;
  left: 50% !important;
  top: 0px !important;
  margin: 0px !important;
}

#location-list #filter {
  text-align: center;
  width: 350px;
  margin-bottom: 10px;
}

#page.location-management #filter {
  margin-top: 0px;
  margin-bottom: 20px !important;
}

#page #filter {
  text-align: center;
  margin-top: 20px;
}

#page #filter button,
#location-list #filter button {
  font-size: .85em !important;
  line-height: 18px;
  background: $secondary;
  border-color: $secondary;
}

#page #filter button:focus,
#location-list #filter button:focus {
  background: $ok;
}

#page #filter button .material-icons,
#location-list #filter button .material-icons {
  font-size: 11px;
  color: $fifth;
  top: 1px;
  margin-right: 2px;
  position: relative;
}

.btn-secondary.focus,
.btn-secondary:focus .material-icons,
.btn-secondary:not(:disabled):not(.disabled):active .material-icons,
#page #filter button:hover .material-icons,
#location-list #filter button:hover .material-icons {
  color: $neutral1 !important;
}

#page #filter button:first-of-type,
#location-list #filter button:first-of-type {
  margin-right: 5px;
}

#page #filter button:last-of-type,
#location-list #filter button:last-of-type {
  margin-left: 5px;
}

.search {
  padding: 10px 10px 0px 10px;
  left: 0px;
  top: 0px;
  z-index: 3;
  background: $neutral1;
  width: 345px !important;
}

.search input,
.search button {
  position: absolute;
  top: 3px;
  height: 35px;
}

.search button .material-icons {
  font-size: .9em;
}

.search input {
  font-size: .9em;
}

#place {
  width: calc(100% - 350px);
  height: calc(100vh - 56px);
  overflow-y: auto;
  left: 350px;
  top: 0px;
  position: absolute;
  background: $neutral1;
}

.frontpage #place .place-innercontainer {
  max-height: 300px;
  overflow-x: auto;
  margin-bottom: -10px;
}

#place #details {
  position: absolute;
  z-index: 3;
  width: 504px;
}

.frontpage #place #details {
  z-index: 9999;
  right: 10px;
  background: $neutral1;
}


.frontpage #place #details .description #featuredImage {
  width: 30%;
  float: right;
  margin: 0px 15px 15px 15px;
  background: $neutral2;
}

.frontpage #place #details .no-description #featuredImage {
  width: auto;
  margin-right: 4px;
}

.frontpage #place #details .description { margin-top:0px;}

#place #details .meta {
  clear: both;
}

#place #details .metadata {
  font-size: 0.9em;
  line-height: 1em;
  background: $neutral3;
  margin: 30px -20px -23px -20px;
  border-top: 1px solid $neutral2;
}

.module-view #place #details .metadata {
  margin: 30px 0px -23px 0px;
}

#place #details .player-wrapper {
  margin-bottom: 50px;
}

.metadata .button {
  display: block;
  padding: 8px;
  text-align: center;
  line-height: 2.5em;
  font-size: .85em;
  font-weight: bold;
}

.metadata .open-page.button {
  width: 100%;
  background: $neutral1;
  color: $base;
}

.metadata .open-page.button:hover {
  background: $secondary;
  color: $neutral1;
  text-decoration: none;
  border-color: $secondary;
}

.favorite {
  color: $favorie;
}

#welcome {
  height: calc(100vh - 100px);
  position: relative;
}

#welcome h1 {
  font-size: 3vw;
  top:50% !important;
  font-weight: bold;
  position:relative;
}

#welcome .welcomeinner {
  margin: 0;
  text-align: center;
  position: absolute;
  top: 55%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#welcome div p.stats { font-size:.85rem; line-height:1.35rem;}
#welcome div p strong { font-size:.8rem;}

.scramble-container {
  font-family: 'Roboto Mono', monospace;
  font-size: 1.3em !important;
  font-weight: 500;
  text-align: center;
  margin-bottom:0px;
  width: 500px !important;
  height:50px !important;
}
.scramble-container div { position: absolute;   text-align:center !important; width:100%;}

#location-list #ShareAndCopy {
  margin: 23px 8px 25px 0px !important;
}

nav.MuiPagination-root:last-of-type {margin-bottom:50px !important;}

#topbar { background:$neutral1; padding:5px 5px 0px 5px; margin-bottom:55px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.01), 
  0 2px 4px rgba(0, 0, 0, 0.01), 
} 

#topbar input,
#topbar select { padding:3px 45px 3px 10px !important;
border-color:$base3 !important; border:1px solid;
border-radius:5px;}
#topbar select { height:33px; margin-top:-5px !important;}


#gridfilter {  position: relative; top:-15px; padding:0px;}
#gridfilter #filter-column { width:270px;}
#gridfilter #filter-selections {  width:calc(100% - 550px); text-align:left; }
#gridfilter #filter-selections select { margin:0 5px 0 5px; background:$neutral1 !important; }
#gridfilter #filter-search { width:250px; text-align:right; margin-top:-5px;}
#gridfilter #filter-search input { padding: 4px 10px 4px 10px!important; width:150px; background:$neutral1 !important; }
#gridfilter #filter-search button { margin:-1px 0px 0px 10px !important; padding:5px 10px 5px 10px;}


#page nav.nav-pills,
#page nav.MuiPagination-root { background-color:transparent !important;}

#page nav.nav-pills {
  top: 15px;
  position:absolute;
  margin-left:20px;
}

#page nav.MuiPagination-root {
  text-align: center;
}

#page nav.MuiPagination-root ul {
  width: auto;
  margin: auto;
}


#page nav.MuiPagination-root  ul {
  display: inline-block;
  margin: 0;
  padding: 0;
  /* For IE, the outcast */
  zoom: 1;
  *display: inline;
}

#page nav.MuiPagination-root ul li {
  float: left;
  margin-right:0px;
 
}

button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-page.MuiPaginationItem-outlined.MuiPaginationItem-rounded.Mui-selected { background:$primary !important; color:$neutral1; border:none;}
button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-page.MuiPaginationItem-outlined.MuiPaginationItem-rounded { background:$neutral1; border:none;}

button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-page.MuiPaginationItem-outlined.MuiPaginationItem-rounded:hover  { background:$secondary; color:$neutral1;}
#page a.nav-item.nav-link { background:$base3 !important; padding:5px 10px 5px 10px !important;}
#page a.nav-item.nav-link.active { background:$primary !important;}
#page a:hover.nav-item.nav-link { background:$secondary !important;}
/** module view **/
.module-view .leaflet-container {
  position: relative !important;
  min-width: 40% !important;
  float: right !important;
  z-index: 0;
}

.module-view #details {
  left: 0px;
  position: absolute;
  width: 60% !important;
  height: calc(100vh - 58px);
}

.module-view #details .innercontainer {
  left: 0px;
  margin-right: 7px;
  padding-right: 10px;
  position: absolute;
  overflow-y: auto;
  height: calc(100vh - 140px);
}

.module-view #place #details .metadata {
  bottom: 21px;
  width: 100%;
  position: absolute;
}

.module-view .welcome {
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

/** Single page and Location list **/
.time-and-place {
  display: flex;
}

.date .material-icons {
  margin-right: 5px;
  font-weight: bold;
}

.date div,
.coordinates {
  font-size: .7rem;
  background: $neutral4;
  display: inline-block;
  margin: 5px 5px 0px 0px;
  background: $neutral2;
  padding: 2px 5px 2px 5px;
  border-radius: 5px;
}

.date .material-icons,
.coordinates .material-icons {
  font-size: .7rem;
  top: 2px;
  position: relative;
}

.tag a,
.tag a:visited {
  padding: 6px 10px 5px 9px;
  font-size: .75rem;
  line-height:1rem;
  background: $neutral2;
  color:$base;
  display: inline-block;
  margin:0px 7px 7px 0px;
  border-radius: 5px;
  position: relative;
}

.tag-name { 
  display:inline-block;
  text-transform: capitalize; }


.tag a:hover,
.tag a:active { text-decoration:none; background:$secondary; color:$neutral1;}

.tag .material-icons {
  font-size: .75rem;
  border-radius: 5px;
  top:2px;
  margin-right:5px;
  position:relative;
  display:inline-block;
}

.react-player {
  margin: 20px 0px 20px 0px;
}

.locationGrid-page .time-and-place .date div,
.locationGrid-page .time-and-place .coordinates {
  background: $sixth;
}

#places .coordinates {
  background: transparent;
  color: $base3;
  margin: 0px;
  padding: 0px;
}

#details .coordinates .material-icons {
  margin-right: 3px;
}

#fullpage.map-view a.link-to-flickr .material-icons,
#fullpage.map-view a.link-to-out .material-icons,
#fullpage.map-view a:visited.link-to-flickr .material-icons {
  margin-right: 2.5px;
  top: 2.5px;
}

a.link-to-out .material-icons,
a.link-to-out .material-icons,
a.link-to-flickr .material-icons,
a:visited.link-to-flickr .material-icons {
  font-size: .85rem;
  position: relative;
  margin-right: 5px;
  top: 3px;
}

.meta {
  margin-top: 20px;
  line-height: 1.1rem;
}

.description {
  margin: 20px 8px 0px 0px;
  font-size: .85rem !important;
  line-height: 1.4rem;
}

a.link-to-out,
a.link-to-out,
a.link-to-flickr,
a:visited.link-to-flickr {
  font-size: .67rem;
  margin-right: 8px;
  color: $base2;
  display: block;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: pre;
}

.flickr-lightbox-container {
  position: relative;
}

.flickr-lightbox div {
  background: $neutral1;
}

.flickr-lightbox {
  margin: 25px 0px 0px 0px;
  min-height: 45px;
  position: relative;
  z-index: 1;
}

.flickr-lightbox img {
  width: 55px;
  height: 55px;
  background: $neutral5;
  position: relative;
}


/** Tag-view **/
.tagview-page  { background:$neutral1 !important;}
.tagview-page .container {
  background: $neutral1;
  padding: 0px;
}
.tagview-page h4#tagName { text-transform: capitalize !important;}
.tagview-page #tag-details {margin:0px;}
.tagview-page .leaflet-container { height:300px;}
.tagview-page .tag-place { display:flex; padding:20px; }
.tagview-page .tag-place:nth-child(odd) { background:$neutral7; }
.tagview-page .tag-place .headline-description { width:80%;}
.tagview-page .tag-place .headline-description div a { display:Block;}
.tagview-page .tag-place .image { width:20%; text-align:right;}
.tagview-page #tag-locations { border:1px solid $primary;}
.tagview-page .tag-details {  overflow-y: auto;  height: calc(100vh - 56px);}
.tagview-page .details {
  overflow-y: auto; padding-top:0px !important;
  height: calc(100vh - 126px);
}
.tagview-page .col-sm { padding:0px;}
.tagview-page #tag-details { margin:20px; }
.tagview-page #headline { 
  position:fixed;
  width:100%; top:56px; height:69px !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.01), 
  0 2px 4px rgba(0, 0, 0, 0.01)
}
.tagview-page .details .go-back { margin:17px 20px 0px 20px;  }
.tagview-page .details { margin-top:70px;}
.tagview-page .tags { margin-top:30px;}
.tagview-page .tag a.active { background:$primary; color:$neutral1;}
.tagView-page .description,
.headline-description div  { font-size:.8rem !important; line-height:1.3rem !important;}
.tagview-page .headline-description { padding-right:15px !important;}

/** Map-view **/
.leaflet-container {
  height: calc(100vh - 56px);
}

#fullpage.map-view .leaflet-container {
  height: calc(100vh - 56px);
  width: 100vw !important;
}

#fullpage.map-view .description {
  margin-top: 0px;
}

.leaflet-popup-content-wrapper {
  width: 290px;
  // max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.leaflet-popup-content iframe {
  width: 240px;
  height: auto;
}

.leaflet-popup-content .player-wrapper {
  margin-bottom: 70px;
}

.leaflet-popup-content .flickr-lightbox img {
  width: 57px;
  height: 57px;
}

.leaflet-popup-content h5 {
  margin-top: 5px;
}

.leaflet-popup-content .metadata {
  font-size: 0.9em;
  line-height: 1em;
  margin: 10px 0px;
}

.leaflet-popup-content .map-innercontainer {
  height: 280px;
  overflow-x: auto;
  margin: 20px 0;
}

.leaflet-popup-content .description {
  font-size: 1.05em !important;
  line-height: 1.45em;
  margin-bottom: -5px;
}

.leaflet-popup-content .meta {
  font-size: 1.2em !important;
}

.leaflet-popup-content .meta a,
.leaflet-popup-content .meta a:visited {
  color: $base;
}

.leaflet-popup-content .button {
  font-size: 0.98em;
}

.leaflet-control-layers.leaflet-control {
  margin: 14px !important;
}

button#box-zoom-button {
  margin: 5px 15px !important;
  width: 35px !important;
  height: 35px !important;
  background-repeat: no-repeat !important;
  background-position: 3px 4px !important;
  border: none !important;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  border-radius: 5px !important;
}

.search-control-info-list-item {
  border-color: $neutral2 !important;
}

article.search-control-wrap {
  margin: 5px 8px;
}

button.search-control-icon-button {
  width: 35px !important;
  height: 35px !important;
}


.search-control-active .search-control-icon-button,
button.search-control-close-button.search-control-close-button-active,
input.search-input.search-control-input,
.search-control {
  height: 35px !important;
}


button.search-control-icon-button svg {
  max-height: 15px !important;
  max-width: 15px !important;
  margin: 10px !important;
}

section.search-control-info-wrapper {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
}

li.search-control-info-list-item.active,
li.search-control-info-list-item.active:hover {
  background: $neutral4 !important;

}


li.search-control-info-list-item:hover {
  background: $neutral4 !important;
}

li.search-control-info-list-item:visited,
li.search-control-info-list-item:hover,
li.search-control-info-list:focus,
li.search-control-info-list-item.candidate,
li.search-control-info-list-item:active,
li.search-control-info-list-item:focus,
li.search-control-info-list-item:not(.active).candidate,
li.search-control-info-list-item:not(.active):active,
li.search-control-info-list-item:not(.active):focus {
  background: $neutral4 !important;
}


li.search-control-info-list-item {
  padding: 10px 15px 10px 15px;
}

.nav-tabs {
  border-bottom: 0px;
  margin-bottom: 0px;
  position: sticky;
  top: 0;
  z-index: 9999;
  background: $neutral1 !important;
  padding-bottom: 15px;
  margin-top: -1px;
  align-items: center;
  justify-content: center;
}

.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: $secondary;
  color: $neutral1 !important;
  border-color: $secondary;
}


.leaflet-popup-content .metadata .open-page.button {
  border: 1px solid $neutral6;
  border-radius: 5px;
  margin-bottom: 5px;
}

.nav-tabs .nav-link {
  background: $neutral1;
  color: $base !important;
  border: 1px solid $neutral6;
  width: calc(33.333% - 5px);
  text-align: center;
  padding: 10px !important;
}

.nav-tabs .nav-link:hover {
  border-color: $neutral4;
  background: $neutral4;
}


.tab-content>.tab-pane img {
  width: auto;
  min-width: 97%;
  max-width: 97%;
}

.tab-content>.tab-pane .coordinates {
  width: 280px;
  text-align: center;
  padding:7px 30px 5px 30px;
}

.tab-content>.tab-pane .coordinates .material-icons { margin-left:-7px; position:relative;}

.tab-content>.tab-pane .get-directions a.btn.btn-info.btn-sm {
  width: 100%;
  padding: 10px;
  margin: 10px 0 15px 0;
}

.tab-content>.tab-pane #ShareAndCopy {
  margin-top: 20px;
  margin-bottom: 0px;
}

.tab-content>.tab-pane #ShareAndCopy span.material-icons {
  margin-left: 30px;
}

div#noanim-tab-example-tabpane-Tiedot {
  padding-top: 0px;
}

.tab-content>.tab-pane .date div {
  width: 100px;
  text-align: center;
}

.tab-content>.tab-pane .flickr-lightbox { margin-top:5px !important;}

/** Location Grid **/
.locationGrid-page {
  padding: 0px;
}

.locationGrid-page .location {
  width: 33.3333%;
  margin-bottom: 10px;
}

.locationGrid-page .location .innercontainer {
  background: $neutral1;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.01),
    0 2px 4px rgba(0, 0, 0, 0.01),
    0 4px 8px rgba(0, 0, 0, 0.01),
    0 8px 16px rgba(0, 0, 0, 0.01),
    0 16px 32px rgba(0, 0, 0, 0.01),
    0 32px 64px rgba(0, 0, 0, 0.01);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.01),
    0 2px 4px rgba(0, 0, 0, 0.01),
    0 4px 8px rgba(0, 0, 0, 0.01),
    0 8px 16px rgba(0, 0, 0, 0.01),
    0 16px 32px rgba(0, 0, 0, 0.01),
    0 32px 64px rgba(0, 0, 0, 0.01);
  border-radius: 3px;
  min-height: 510px;
  font-size: .9em;
}

.locationGrid-page .location .metadata {
  border-bottom: 1px solid $neutral3;
}

.locationGrid-page .location .location-head {
  background: $primary;
  color: $neutral1;
  padding: 20px;
  z-index: 9991;
  border-radius: 3px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 65px;
}

.locationGrid-page .location .location-head h5 {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.locationGrid-page .location .location-head h5 a,
.locationGrid-page .location .location-head h5 a:visited {
  color: $neutral1;
  font-weight: normal;
}

.locationGrid-page .location .location-body {
  max-height: 446px;
  overflow-y: auto;
}

.locationGrid-page .location .location-inner-body {
  margin: 20px;
}

.locationGrid-page .leaflet-container {
  height: 200px;
}

// Archive page
.content-page.archive .tags { margin:25px 0px 40px 0px; }
.archive-content  {
  column-count: 3;
  column-gap: 40px;
  margin-top:30px;
  font-size:.85rem;
  line-height:1.2rem;
}

.archive-content li { margin-bottom:5px;}

// Weather
#weather p {
  color: $base;
  margin: 0px -100px;
  text-shadow: -0.8px 0.8px 5px $neutral1,
    0.8px 0.8px 5px $neutral1,
    0.8px -0.8px 5px $neutral1,
    0.8px -0.8px 5px $neutral1;
  font-weight: bold;
  font-size: 0.9rem;
}

#weather-bg {
  height: 100px;
  margin-top: -130px !important;
  width: 300px;
  border: 1px solid $neutral1;
  border-radius: 10px;
  margin: 0 auto;
  position: relative;
  z-index: 999;
  background-color: rgba(255, 255, 255, .9);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.01),
    0 2px 4px rgba(0, 0, 0, 0.01),
    0 4px 8px rgba(0, 0, 0, 0.01),
    0 8px 16px rgba(0, 0, 0, 0.01),
    0 16px 32px rgba(0, 0, 0, 0.01),
    0 32px 64px rgba(0, 0, 0, 0.01);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.01),
    0 2px 4px rgba(0, 0, 0, 0.01),
    0 4px 8px rgba(0, 0, 0, 0.01),
    0 8px 16px rgba(0, 0, 0, 0.01),
    0 16px 32px rgba(0, 0, 0, 0.01),
    0 32px 64px rgba(0, 0, 0, 0.01);
}

@supports ((-webkit-backdrop-filter: blur(3px)) or (backdrop-filter: blur(3px))) {
  #weather-bg {
    background: transparent;
    backdrop-filter: blur(3px);
  }
}

.styles_simpleReactWeatherTemp__2PnUn {
  font-size: 2.3rem !important;
}

#weather div {
  position: relative;
  color: $base;
  z-index: 9991;
  text-shadow: -0.8px 0.8px 5px $neutral1,
    0.8px 0.8px 5px $neutral1,
    0.8px -0.8px 5px $neutral1,
    0.8px -0.8px 5px $neutral1;
}

.frontpage #weather,
.locationView-page #weather,
.locationGrid-page #weather {
  z-index: 400;
  position: relative;
  width: 200px;
}

.locationView-page #weather {
  margin: 0 auto 0 auto;
  bottom: 0px;
  top: 30px;
  left: 7px;
}

/* Module and frontpage */
.frontpage #weather div,
.module-view #weather div {
  left: 22px;
  top: 12px;
}

.frontpage #weather p.styles_simpleReactWeatherTemp__2PnUn,
.module-view p.styles_simpleReactWeatherTemp__2PnUn {
  top: 15px;
  right: 45px;
  width: auto;
  font-size: 1.5rem !important;
}

.locationView-page p.styles_simpleReactWeatherCity__zyECF {
  top: 6px;
}

.frontpage #weather p.styles_simpleReactWeatherCity__zyECF,
.module-view p.styles_simpleReactWeatherCity__zyECF {
  top: 6px;
  font-size: .7rem !important;
}

.frontpage #weather-bg,
.module-view #weather-bg {
  overflow: hidden;
  left: calc(50% - 200px);
  right: calc(50% - 200px);
  height: 75px !important;
  bottom: 30px !important;
  position: absolute;
  width: 200px;
}

.frontpage p.styles_simpleReactWeatherTemp__2PnUn {
  top: 12px !important;
}

.frontpage .styles_simpleReactWeatherIcon__3sqXV {
  font-size: 1.1rem;
}

.frontpage .styles_simpleReactWeather__3uy4l {
  left: 23px !important;
  top: 12px !important;
}

/** Locations-Management */
.location-management {
  padding: 0px;
}

.location-management .page-navigation {
  margin: -30px 0;
}

.location-management .leaflet-container {
  height: 100% !important;
}

.location-management .row {
  padding: 10px 30px 10px 30px;
  width: 100%;
  margin: 0 auto;
  min-height: 100px;
}

.location-management .row .headline h6 {
  font-size: .95em;
  margin-top: 10px;
}

.location-management .row .headline {
  margin-bottom: 10px,
}

.location-management .row .headline .time-and-place {
  display: inline;
  margin-left:5px;
}

.location-management .row .headline .time-and-place div {
  background: transparent;
  margin: 0px;
  padding: 0px;
  text-align:left;
}

.location-management .row .description {
  font-size: .85em !important;
  line-height: 1.45em;
  margin: 10px 20px 0px 20px;
}

.location-management .row:first-of-type {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.location-management .row:last-of-type {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}


.location-management .row:nth-of-type(even) {
  background: $neutral7;
}

.location-management .row:nth-of-type(odd) {
  background: $neutral1;
}

.location-management .row:last-of-type {
  margin-bottom: 20px;
}

.location-management .control .open,
.location-management .control .edit {
  margin: 0 auto !important;
  position: relative;
  top: 8px;
}

/** Location single page **/
.locationView-page .container {
  background: $neutral1;
  padding: 0px;
}

.locationView-page .innercontainer { margin-left:8px;}

.locationView-page .map {
  margin-left: -30px;
}

.locationView-page .flickr-lightbox img {
  width: 65px;
  height: 65px;
}

.module-view #featuredImage,
.locationView-page #featuredImage {
  margin-top: 15px;
}

.locationView-page .map .leaflet-container {
  height: calc(100vh - 56px);
}

.locationView-page .details {
  overflow-y: auto;
  height: calc(100vh - 56px);
  padding: 0px;
}

.locationView-page .details h4 {
  margin-top: 20px;
}

h4 .material-icons {
  display: inline-block;
  right: 20px;
  position: absolute;
}

.tagview-page .details .go-back .material-icons,
.locationView-page .details .go-back .material-icons {
  font-size: .8em;
  font-weight: bold;
  margin: 0px -30px 0px 5px;
}

.tagview-page .details .go-back,
.locationView-page .details .go-back {
  font-size: .75em;
  border-radius: 20px;
  background: transparent;
  padding: 6px 20px 4px 12px;
  border: 1px solid $neutral2;
  font-weight: bold;
  color: $base2;
}

.tagview-page .details .go-back:hover,
.locationView-page .details .go-back:hover {
  background: $primary;
  border-color: $primary;
  color: $neutral1;
}

.locationView-page .player-wrapper {
  margin-bottom: 40px;
}

.locationView-page .edit {
  margin-top: 20px;
}

.get-directions {
  margin: 10px 0px 10px 0px;
}

.get-directions .material-icons {
  font-size: .6rem;
  margin-right: 5px;
}

.get-directions a,
.get-directions a:visited {
  background: $third;
  border-color: $third;
  color: $neutral1;
  font-size: .8rem;
}

.get-directions a:hover,
.get-directions a:active {
  background: $ok;
  border-color: $ok;
}

/** Sisältösivut **/

.content-page h1,
.content-page h2,
.content-page h3,
.content-page h4,
.content-page h5 {
  margin-bottom: 15px;
}

.content-page p {
  max-width: calc(100em * 0.5);
}

.content-page h3 {
  margin-top: 40px;
}

.content-page h5 {
  margin-top: 30px;
}

.content-page h6 {
  margin-top: 0px;
}

.content-page .row {
  margin: 0px -15px 0px -15px !important;
  padding: 0px !important;
}

.content-page .space {
  margin-top: 30px !important;
}

/** Video, lightbox, player & toast **/
.Toastify__toast--default {
  background: $neutral1 !important;
  color: $base !important;
  padding: 25px !important;
  font-size: .9em;
  border-radius: 5px !important;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

#lightboxBackdrop {
  z-index: 9999;
}

@media screen and (max-width: 1400px) {
  .row {
    width: 100% !important;
  }

  .content-page .row {
    margin: 0px !important;
  }
}
@media screen and (min-width: 1200px) {

  #welcome h1 { font-size:2.3rem;}
}
@media screen and (max-width: 1100px) {

  .module-view .styles_simpleReactWeather__3uy4l {
    display: flex;
  }

  .module-view p.styles_simpleReactWeatherCity__zyECF {
    top: -0px;
  }

  .module-view .styles_simpleReactWeatherCityIcon__wxZcO {
    margin-top: 15px !important;
    left: 10px !important;
  }

  .module-view #weather div {
    top: 0px;
  }

  .module-view #weather-bg {
    margin: 5px;
    left: 20px;
    right: 0;
    top: 150px;
    height: 64px !important;
  }

  .module-view #weather {
    top: 40px;
    left: 40px;
  }

  #place .place-innercontainer {
    max-height: calc(50vh - 140px) !important;
  }

  #location-list .metadata.flex {
    top: calc(50vh - 130px) !important;
    position: absolute;
    width: 100%;
  }

  #location-list #details {
    width: 100% !important;
    height: calc(50vh - 55px);
    right: 0px !important;
  }


  #location-list .leaflet-container {
    height: 50vh;
    width: 100%;
    bottom: 0;
    position: absolute !important;
  }

  .module-view #details {
    height: calc(80vh - 112px) !important;
    bottom: 0px;
  }

  .module-view #place .place-innercontainer {
    max-height: 100% !important;
  }

  .module-view #details .innercontainer {
    height: auto;
    top: 0px;
    width: calc(100% - 30px);
  }

  .module-view #place #details .metadata {
    left: 0px;
  }

  .module-view #place #details .metadata.flex {
    top: -86px !important;
    height: 50px;
    border-bottom: 1px solid $neutral2 !important;
  }

  .module-view .leaflet-container {
    height: 20vh !important;
    top: 0;
  }

  .module-view #weather {
    width: auto;
    height: 0px;
    top: 10px;
    left: 10px;
    width: 200px;
    height: 100px;
  }

  .module-view #weather p {
    margin: 5px 0px 10px 0px !important;
    text-align: left;
    width: 100%;
    margin: 0px;
    width: 100px;
    display: block;
    font-size: 1em;
  }

  .module-view .styles_simpleReactWeatherCityIcon__wxZcO {
    margin-left: 10px;
  }

  .module-view .styles_simpleReactWeatherTemp__2PnUn {
    left: 10px !important;
    font-size: 1.2rem !important;
    margin-left: -100px !important;
  }

  .module-view #weather div {
    left: 0px;
    text-align: left;
  }
}

@media screen and (max-width: 1050px) { 
  #gridfilter { margin: 0 15px 0 15px !important;}
  #gridfilter #filter-column { width:270px;}
  #gridfilter #filter-selections {  width:calc(100% - 270px); text-align:right; }
  #gridfilter #filter-search { margin-top:10px !important; width:100%; text-align:left;}
  #gridfilter #filter-search input { width:calc(100% - 60px);}
}

@media screen and (max-height: 550px) {
  .module-view #weather,
  .module-view #weather-bg {
    display: none;
  }
}

@media screen and (max-width: 991px) { 
  .location-management .row .description { margin:0px 15px 0px 15px; padding:0px;}
  .location-management .row .open { float:none; margin:10px auto 10px auto !important;}
}

@media screen and (max-width: 960px) {
  .archive-content  {
    column-count: 2;
  }

  button#box-zoom-button {
    display: none;
  }

  nav ul li {
    display: inline-block;
    margin-right: 0px;
  }

  nav a .material-icons {
    top: 4.8px;
    margin-right: 0px;
  }

  .navbar {
    padding: 0px;
  }

  a.navbar-brand {
    display: none;
  }

  nav .nav-title {
    font-size: .75rem !important;
    display: block;
  }

  .navbar-nav,
  nav ul {
    width: 100%;
  }

  li.nav-item {
    width: 16.666%;
  }

  nav ul li a.nav-link,
  nav ul li a.nav-link:visited {
    padding: 10px;
    text-align: center;
    margin: 0px 0px 0px 0px;
    border-radius: 0px;

  }

  .locationGrid-page .location {
    width: 100%;
    margin: 0 0 0 10px;
  }

  .scramble-container {
    width: 400px !important;
    font-size: 1em !important;
  }

  .tagview-page #headline { 
    top:63px;
  }
}

@media screen and (max-width: 850px) {

  aside {
    width: 251px;
  }

  #location-list #filter {
    width: 250px;
  }

  #location-list .search,
  #location-list #places {
    width: 250px !important;
  }

  #location-list #place {
    width: calc(100% - 250px) !important;
    left: 250px !important;
  }
}

@media screen and (max-width: 766px) {

  .locationView-page .styles_simpleReactWeatherCity__zyECF {
    left: -10px;
    font-size: .7rem !important;
    top: 23px !important;
    position: absolute;
  }

  .locationView-page .styles_simpleReactWeather__3uy4l {
    display: flex;
  }

  .locationView-page .styles_simpleReactWeatherCityIcon__wxZcO {
    position: absolute;
    font-size: .2rem !important;
    margin-top: 15px !important;
    left: 0px !important;
  }

  .locationView-page #weather div {
    top: 0px;
    float: right;
  }

  .locationView-page .styles_simpleReactWeatherIcon__3sqXV {
    left: -110px;
    top: 0px;
    font-size: 1.2rem !important;
    position: absolute;
  }

  .locationView-page p.styles_simpleReactWeatherTemp__2PnUn {
    position: relative;
    top: 14px;
    left: 90px;
    font-size: 1.5rem !important;
  }

  .locationView-page #weather-bg {
    margin: 5px;
    left: 20px;
    right: 0;
    top: 145px;
    height: 62px !important;
    width: 200px;
    position: absolute;
  }

  .locationView-page #weather {
    top: 0px;
    left: 40px;
  }

  .locationView-page .innercontainer {
    margin: 25px 20px 30px 20px !important;
  }

  #page.content-page .row {
    margin: 0px -20px 0 0 !important;
  }

  #page.content-page .col-sm:first-of-type {
    margin-top: 0px;
  }

  #page.content-page .col-sm {
    min-width: 100%;
    margin-top: 30px;
  }

  form .subdetails {
    margin: 0px;
  }

  #page.locationView-page {
    background: $neutral1;
  }

  .subdetails {
    text-align: center;
    margin-bottom: 0px;
  }

  .subdetails .col-sm-4,
  .subdetails .col-sm-5 {
    max-width: 100% !important;
  }

  .subdetails .col-sm-5 {
    margin: 0px !important;
    padding: 0px !important;
  }

  .subdetails button {
    width: 100% !important;
  }

  .editlocation .form-group,
  .addlocation .form-group {
    border-radius: 0px !important;
  }

  .editlocation .row,
  .addlocation .row {
    width: 100% !important;
    display: block !important;
    margin: 0px !important;
  }

  .col-sm {
    padding: 0px;
  }

  .locationView-page .map {
    position: absolute;
    top: 0px;
    right: 0;
    width: 100vw !important;
    height: 60vh !important;
  }

  .locationView-page .map .leaflet-container {
    height: 60vh !important;
  }

  .locationView-page .details {
    margin-top: 60vh !important;
    height: auto;
  }

  .location-management .row .description {
    margin: 1px;
    padding: 0px;
}

}

@media screen and (max-width: 710px) {
  #gridfilter #filter-column { width:100%; height:43px;}
  #gridfilter #filter-selections {  width:100%; text-align:left;  margin-left:-5px; }
  #gridfilter #filter-search { margin-top:10px !important; width:100%; text-align:left;}
  #gridfilter #filter-search input { width:calc(100% - 60px);}
  .tagview-page .col-sm  { width:100% !important;
  min-width:100% !important; flex-basis:100% !important;}

  .tagview-page #headline { position: relative !important;
  top:0px !important;   box-shadow: none;}
  .tagview-page .details .go-back { position:relative; top:-9px !important;}
  
  .tagview-page .details { margin-top:0px;}
  .tagview-page .tag-details,
  .tagview-page .details {
    overflow-y: auto;
    height: auto !important;
  }

}
 
@media screen and (max-width: 600px) {
  .archive-content  {
    column-count: 1;
  }

  #location-list #ShareAndCopy {
    margin-right: 0px !important;
  }

  .metadata .open-page.button {
    line-height: 2rem;
  }

  .leaflet-control-container {
    display: none;
  }

  .locationView-page .leaflet-control-container,
  .map-view .leaflet-control-container {
    display: block;
  }

  .frontpage .styles_simpleReactWeatherCity__zyECF {
    left: -15px;
    font-size: .7rem !important;
    top: 21px !important;
    position: absolute;
  }

  .frontpage .styles_simpleReactWeather__3uy4l {
    display: flex;
  }

  .frontpage.styles_simpleReactWeatherCityIcon__wxZcO {
    position: absolute;
    font-size: .2rem !important;
    margin-top: 15px !important;
    left: 0px !important;
  }

  .frontpage #weather div {
    top: 0px;
    float: right;
  }

  .frontpage .styles_simpleReactWeatherIcon__3sqXV {
    left: -115px;
    top: 0px;
    font-size: 1.2rem !important;
    position: absolute;
  }

  .frontpage p.styles_simpleReactWeatherTemp__2PnUn {
    position: relative;
    top: -3px !important;
    left: 105px !important;
    font-size: 1.5rem !important;
  }

  .frontpage #weather-bg {
    margin: 5px;
    left: 8px;
    right: 0;
    top: 144px;
    height: 59px !important;
    width: 200px;
  }

  .frontpage #weather {
    top: 4px;
    left: 0px;
  }

  .locationGrid-page .date {
    display: none;
  }

  #welcome h1 {
    font-size: 2em;
  }

  nav li.module {
    display: none;
  }

  nav li.nav-item {
    width: 20%;
  }

  aside {
    width: 100%;
    border: none;
    z-index: 0;
  }

  #page .spinner-border {
    left: 50% !important;
    width: 30px;
    height: 30px;
    margin-left: -15px;
  }

  #location-list .spinner-border {
    left: 46% !important;
    top: 150px !important;
  }

  #places .list-group-item {
    padding: 3px 10px 3px 10px;
  }

  #places .list-group-item .coordinates {
    display: none;
  }

  #location-list #filter {
    width: 100%;
  }

  .scramble-container {
    font-size: 1.1em !important;
    width: 100% !important;
  }

  #location-list .search {
    width: 100% !important;
  }

  #welcome  {
    position:relative !important;
    margin-bottom:0px;
    height: auto !important;
  }

  #welcome .welcomeinner {
    position:relative !important;
    margin-right: auto !important;
    transform:none !important;
    top:auto !important;
    left:auto !important;
    height:560px !important;
    padding-top:30px;
  }

  #place::-webkit-scrollbar {
    width: 5px;
  }

  #location-list #places {
    width: 100% !important;
    height: calc(50vh - 170px) !important;
  }

  .module-view #details {
    max-height: auto !important;
  }

  .module-view #place #details .metadata.flex {
    top: -30px !important;
  }

  .module-view #details .innercontainer {
    top: 50px;
  }

  #location-list #place {
    width: 100% !important;
    height: 60vh;
    top: calc(50vh - 67px);
    left: 0px !important;
    position: absolute !important;
    border: 1px solid $neutral3;
    background: $neutral1;
  }

  #location-list #place #details {
    top: 20vh;
    height: auto;
    padding-bottom: 100px;
    padding-top: 50px;
  }

  .module-view #place #details .metadata,
  #location-list .metadata.flex {
    top: -30px !important;
    position: absolute;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid $neutral3 !important;
  }

  #location-list #place .place-innercontainer {
    height: auto !important;
    max-height: 100% !important;
    min-height: auto !important;
  }

  #location-list .leaflet-container {
    position: absolute;
    width: 100%;
    top: 0vh;
    height: 20vh !important;
  }
}

@media screen and (max-width:500px) {
  #page.content-page .innerwidth:first-child {margin-top:0px !important;}
  #page.content-page .innercontainer {margin: 0px;}

  #fullpage.map-view .leaflet-container {
    height: calc(100vh - 40px);
}

  div#gird {
    margin: 20px 0px 30px -10px !important;
  }

  span.nav-title { display:none !important;}
  #page {height: calc(100vh - 41px) !important; }

  #gridfilter #filter-selections select { 
  width:100% !important;
  margin-bottom:10px;}

  #gridfilter #filter-selections select:last-child { margin-bottom:0px;}
  
  #welcome .welcomeinner {
    position:relative !important;
    margin-right: auto !important;
    transform:none !important;
    top:auto !important;
    left:auto !important;
    height:500px !important;
    padding-top:30px;
  }

  .flickr-lightbox {
    overflow: hidden;
  }
}

@media screen and (max-width: 350px) {
  nav a .material-icons {
    top: 3px;
  }

  nav .nav-title {
    display: none !important;
  }
}

/** Disco **/
#breakdance {
  display: none;
}

.modal-90w {
  max-width: 90% !important;
}